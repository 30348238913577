import React from "react";

const updateTariffBundles = (getLangPath, getParams) => ({
  oa: {
    title: "Amazon Seller's Bundle",
    subTitle: (
      <>
        A complete toolset for beginners and experienced Amazon sellers
        <span>+</span>
        <span>Hot Products Subscription</span>
      </>
    ),
    payTitle: "Pay $399",
    payPeriod: "/year",
    discountStrikethroughText: "$1,084",
    // discountText: " $349.99 billed annually",
    buyNow: {
      category: "PricingLP",
      action: "BuyNow",
      path: `${getLangPath}/checkout/?offerId=bundle_annual${getParams}`,
      text: "Buy now",
    },
    learnMore: {
      show: true,
      category: "PricingLP",
      action: "Learn more",
      path: "/amazon-sellers-bundle/?r=hot_products_paywall",
      text: "Learn more",
    },
    refund: (
      <p className="PriceList-Tariff__refund">10-day money-back guarantee</p>
    ),
  },
  bundle: {
    title: "Amazon Seller's Bundle",
    subTitle: (
      <>
        A complete toolset for beginners and experienced Amazon sellers
        <span>+</span>
        <span>Hot Products Subscription</span>
      </>
    ),
    payTitle: "Pay $1,599",
    payPeriod: "/lifetime",
    // discountStrikethroughText: "$239.88",
    discountText: " $199.99 billed annually",
    buyNow: {
      category: "PricingLP",
      action: "BuyNow",
      path: `${getLangPath}/checkout/?offerId=bundle_lifetime${getParams}`,
      text: "Buy now",
    },
    learnMore: {
      show: true,
      category: "PricingLP",
      action: "Learn more",
      path: "/amazon-sellers-bundle/?r=hot_products_paywall",
      text: "Learn more",
    },
    refund: (
      <p className="PriceList-Tariff__refund">10-day money-back guarantee</p>
    ),
  },
});
export default updateTariffBundles;
